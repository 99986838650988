import React from 'react';
import {
  Grid,
  HStack,
  Image,
  Text,
  VStack,
  Heading,
  Center,
  Box,
} from '@chakra-ui/react';
import customizedBlocking from '../images/customized-blocking.png';
import personalizedFiltering from '../images/personalized-filtering.png';
import flexiblePauseOptions from '../images/flexible-pause-options.png';
import improvedConsumption from '../images/improved-consumption.png';
import reduceOverload from '../images/reduce-overload.png';

const BenefitsLeft = ({ heading, text, image }) => {
  return (
    <HStack>
      <Box width={'50%'} display="flex" justifyContent="center">
        <Image marginRight={'70px'} src={image} boxSize={'45%'} />
      </Box>
      <VStack width={'50%'}>
        <Heading marginBottom={'45px '}>{heading}</Heading>
        <Text fontSize={'1.5rem'} whiteSpace={'wrap'} textAlign={'center'}>
          {text}
        </Text>
      </VStack>
    </HStack>
  );
};

const BenefitsRight = ({ heading, text, image }) => {
  return (
    <HStack>
      <VStack width={'50%'}>
        <Heading marginBottom={'45px '}>{heading}</Heading>
        <Text fontSize={'1.5rem'} whiteSpace={'wrap'} textAlign={'center'}>
          {text}
        </Text>
      </VStack>
      <Box width={'50%'} display="flex" justifyContent="center">
        <Image marginLeft={'70px'} src={image} boxSize={'45%'} />
      </Box>
    </HStack>
  );
};

const Benefits = () => {
  return (
    <Center>
      <VStack>
        <Text
          fontWeight={'semibold'}
          marginTop={'45px'}
          marginBottom={'45px'}
          fontSize="3xl"
        >
          How UnDistracted can help you
        </Text>
        <Grid
          templateColumns="repeat(1, 1fr)"
          templateRows="repeat(5, 1fr)"
          gap={20}
        >
          <BenefitsLeft
            heading="Personalized Filtering"
            text="Provides fully customizable filtering options that can be tailored to perfectly match each individual's unique preferences and requirements."
            image={personalizedFiltering}
          />
          <BenefitsRight
            heading="Customizable Blocking"
            text="Allows selective blocking of specific features on platforms like Facebook, YouTube, Twitter and other popular social media."
            image={customizedBlocking}
          />
          <BenefitsLeft
            heading="Flexible Pause Options"
            text="Offers flexibility to temporarily pause filters for customizable durations, such as specific hours, 5-minute breaks, or even entire days, allowing for personalized breaks or focused sessions."
            image={flexiblePauseOptions}
          />
          <BenefitsRight
            heading="Improved Content Consumption"
            text="Encourages mindful content consumption by hiding irrelevant or overwhelming content."
            image={improvedConsumption}
          />
          <BenefitsLeft
            heading="Reduced Digital Overload"
            text="Prevents information overload and promotes mental clarity by blocking distracting elements."
            image={reduceOverload}
          />
        </Grid>
      </VStack>
    </Center>
  );
};

export default Benefits;
