import React from 'react';
import { Center, VStack, Text, Heading } from '@chakra-ui/react';

const Title = () => {
  return (
    <Center className="title">
      <VStack>
        <Heading
          as="h1"
          size="3xl"
          marginTop={'100px'}
          fontWeight={'semibold'}
          textAlign="center"
        >
          Reclaim control,{' '}
          <Text color="accentedTextColor" display={'inline'}>
            Block
          </Text>{' '}
          Social Media{' '}
          <Text color="accentedTextColor" display={'inline'}>
            Distractions
          </Text>
        </Heading>
        <Heading
          as="h3"
          size="lg"
          marginTop={'70px'}
          fontWeight={'normal'}
          textAlign="center"
          width={'80%'}
        >
          Reclaim mastery over your digital world and rid yourself of social
          media distractions with UnDistracted.
        </Heading>
      </VStack>
    </Center>
  );
};

export default Title;
