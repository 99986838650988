import { Text, Heading, Image, VStack, HStack } from '@chakra-ui/react';
import emailIcon from '../../images/email.png';
import phoneIcon from '../../images/phone.png';
// import locationIcon from '../../images/location.png';

import React from 'react';

const ContactUs = () => {
  return (
    <div className="footer-element">
      <Heading as="h2" size="lg" marginTop={'50px'} mb={4}>
        <strong>Contact Us</strong>
      </Heading>

      <Text mb={10}>Feel free to reach out</Text>
      <VStack
        display={'flex'}
        spacing={4}
        justifyContent={'left'}
        alignItems={'left'}
      >
        <HStack>
          <Image src={emailIcon} boxSize={'35px'} alt="email" />
          <Text paddingLeft={'20px'}>
            undistracted.developer@gmail.com{' '}
            <Text fontSize={'0.8rem'}>(fastest response)</Text>
          </Text>
        </HStack>
        <HStack>
          <Image src={phoneIcon} boxSize={'35px'} alt="phone" />
          <Text paddingLeft={'20px'}>+91-96254 46986</Text>
        </HStack>
        {/* <HStack>
          <Image src={locationIcon} boxSize={'35px'} alt="location" />
          <Text paddingLeft={'20px'}>C208, MU1, Greater Noida, UP, India</Text>
        </HStack> */}
      </VStack>
    </div>
  );
};

export default ContactUs;
