import { Heading, Text, Link } from '@chakra-ui/react';

const RefundPolicy = () => {
  return (
    <div className="footer-element">
      <Heading as="h2" size="lg" marginTop={'50px'} mb={4}>
        <strong>Refund Policy</strong>
      </Heading>

      <Text mb={4}>
        Subscription to the paid version of UnDistracted is priced at $2 per
        month. We aim to provide the best service possible and appreciate your
        support.
      </Text>

      <Heading as="h3" size="md" mt={10} mb={2}>
        <strong>Cancellation Policy</strong>
      </Heading>

      <Text mb={4}>
        You can cancel your subscription at any time. To cancel your
        subscription, visit the Settings button of the extension and click on
        the Payments button again. <br /> You will be guided along the process.
      </Text>

      <Heading as="h3" size="md" mt={10} mb={2}>
        <strong>Refund Turnaround Time</strong>
      </Heading>

      <Text mb={4}>
        Refunds will be processed within 15 days of the cancellation request.
      </Text>

      <Heading as="h3" size="md" mt={10} mb={2}>
        <strong>Refund Eligibility</strong>
      </Heading>

      <Text mb={4}>
        If you cancel your subscription, you will continue to have access to the
        premium features until the end of your current billing cycle. No further
        charges will be made after the cancellation. <br />
        <br /> Refunds for the current month are not available as the
        subscription is billed monthly and the service is provided in advance.
      </Text>

      <Text mb={4}>
        If you experience any issues or have any questions regarding your
        subscription, please contact our support team at{' '}
        <Link href="mailto:undistracted.developer@gmail.com">
          undistracted.developer@gmail.com
        </Link>
        <br /> We are here to assist you and ensure a smooth experience.
      </Text>

      <Text mb={4}>
        Please note that by subscribing to the paid version, you agree to this
        refund policy. We reserve the right to modify this policy at any time,
        so please review it frequently.
      </Text>
    </div>
  );
};

export default RefundPolicy;
