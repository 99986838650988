import React from 'react';
import { Box, Flex, HStack, Text, Link, Button } from '@chakra-ui/react';
import { Logo } from '../Logo';

const Header = () => {
  const linkHoverStyle = {
    textDecoration: 'none',
    color: '#0080ff',
    backgroundColor: '#fffff0',
    border: '1px solid #0080ff',
  };
  return (
    <Box
      as="header"
      padding="10px"
      position="sticky"
      top="0"
      backgroundColor={'backgroundColor'}
      zIndex={'1'}
      paddingTop={'10px'}
      paddingBottom={'10px'}
    >
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <Link href="/" _hover={{ textDecoration: 'none' }}>
          <HStack>
            <Logo pointerEvents="none" />
            <Text fontSize="4xl" fontWeight="light" ml="10px">
              UnDistracted
            </Text>
          </HStack>
        </Link>
        <Button
          as={Link}
          href="https://chromewebstore.google.com/detail/undistracted-hide-faceboo/pjjgklgkfeoeiebjogplpnibpfnffkng"
          bg="#0080ff"
          borderRadius="68px"
          color="#fffff0"
          fontWeight="semibold"
          _hover={linkHoverStyle}
        >
          Get Extension
        </Button>
      </Flex>
    </Box>
  );
};

export default Header;
