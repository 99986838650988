import React from 'react';
import {
  Heading,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

const Privacy = () => {
  return (
    <div className="footer-element">
      <Heading mt={8} as="h1" size="lg" mb={4}>
        Privacy Policy
      </Heading>

      <Tabs isFitted variant="line" defaultIndex={0}>
        <TabList mb="1em">
          <Tab>UnDistracted by Nauka</Tab>
          <Tab>Chrome Web Store</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Introduction
            </Heading>
            <Text mb={4}>
              Thank you for using UnDistracted. We value your privacy and are
              committed to protecting your personal data. This privacy policy
              explains our approach to your privacy.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Information We Collect
            </Heading>
            <Text mb={4}>
              We do not collect any personal information from users of
              UnDistracted.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Use of Information
            </Heading>
            <Text mb={4}>
              As we do not collect any personal information, we do not use or
              process any user data.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Sharing Your Information
            </Heading>
            <Text mb={4}>
              We do not share any personal information with third parties
              because we do not collect any personal information.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Data Security
            </Heading>
            <Text mb={4}>
              Even though we do not collect personal information, we implement
              standard security measures to protect any data interactions on our
              platform.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Changes to This Privacy Policy
            </Heading>
            <Text mb={4}>
              We may update this privacy policy from time to time. Any changes
              will be posted on this page.
            </Text>
            <Heading mt={8} as="h2" size="md" mb={2}>
              Contact Us
            </Heading>
            <Text mb={4}>
              If you have any questions about this privacy policy, please
              contact us at undistracted.developer@gmail.com
            </Text>
          </TabPanel>
          <TabPanel>
            <iframe
              src="https://policies.google.com/privacy?hl=en-GB"
              style={{ width: '100%', height: '90vh', border: 'none' }}
              title="Google Privacy Policy"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Privacy;
