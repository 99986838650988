import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  Highlight,
} from '@chakra-ui/react';
import PaddleCheckout from './Paddle';

const Pricing = () => {
  return (
    <VStack>
      <Text
        fontWeight={'semibold'}
        marginTop={'45px'}
        marginBottom={'45px'}
        fontSize="3xl"
      >
        Pricing
      </Text>
      <HStack spacing={'100px'} align={'start'}>
        <Box
          width={'300px'}
          height={'430px'}
          borderRadius={'1rem'}
          border={'3px solid #0080ff'}
        >
          <Text
            marginLeft={'auto'}
            marginRight={'auto'}
            className="semi blue"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color={'white'}
            fontSize={'2xl'}
            fontWeight={'semibold'}
          >
            Free
          </Text>
          <UnorderedList
            styleType="'☄ '"
            fontSize={'lg'}
            display={'flex'}
            flexDir={'column'}
            justifyContent={'center'}
            marginTop={'10%'}
            padding={'0 10px'}
          >
            <ListItem>Hide Feeds</ListItem>
            <ListItem>Hide Likes and Comments</ListItem>
            <ListItem>Hide Trends</ListItem>
            <ListItem>Hide / Blue Thumbnails </ListItem>
            <ListItem>Block Reels and Shorts</ListItem>
            <ListItem>Block custom sites </ListItem>
            <ListItem>Pause filters for time periods </ListItem>
          </UnorderedList>
        </Box>
        <VStack spacing={'0'}>
          <Box
            width={'300px'}
            height={'430px'}
            borderRadius={'1rem'}
            border={'3px solid #ef9b00'}
          >
            <Text
              marginLeft={'auto'}
              marginRight={'auto'}
              className="semi orange"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'white'}
              fontSize={'2xl'}
              fontWeight={'semibold'}
            >
              $2 / month
            </Text>
            <UnorderedList
              styleType="'☄ '"
              fontSize={'lg'}
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              marginTop={'10%'}
              padding={'0 10px'}
            >
              <ListItem>Hide Feeds</ListItem>
              <ListItem>Hide Likes and Comments</ListItem>
              <ListItem>Hide Trends</ListItem>
              <ListItem>Hide / Blue Thumbnails </ListItem>
              <ListItem>Block Reels and Shorts</ListItem>
              <ListItem>Block custom sites </ListItem>
              <ListItem>Pause filters for time periods </ListItem>
              <ListItem>
                <Highlight
                  query="Pause filters on certain days"
                  styles={{
                    px: '1',
                    py: '0.5',
                    bg: 'orange.100',
                    color: 'purple.800',
                  }}
                >
                  Pause filters on certain days
                </Highlight>
              </ListItem>
              <ListItem>
                <Highlight
                  query="Lock settings with password"
                  styles={{
                    px: '1',
                    py: '0.5',
                    bg: 'orange.100',
                    color: 'purple.800',
                  }}
                >
                  Lock settings with password
                </Highlight>
              </ListItem>
              <ListItem
                style={{ listStyleType: 'none' }}
                marginLeft={'-31px'}
                marginRight={'-13px'}
                cursor={'pointer'}
              >
                <PaddleCheckout />
              </ListItem>
            </UnorderedList>
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default Pricing;
