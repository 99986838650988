import React from 'react';
import featureList from './featureList.json';
import {
  Text,
  Center,
  VStack,
  Flex,
  Card,
  CardBody,
  Heading,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

const siteColorCodes = {
  Facebook: '#1877F2',
  YouTube: '#FF0000',
  Twitter: '#1DA1F2',
  LinkedIn: '#0A66C2',
  Instagram: '#E4405F',
  'Other Customizations': '#000000',
};

const FeatureCard = ({ title, features }) => {
  const cardProps = {
    margin: '10px',
    borderRadius: '0.75rem',
    color: 'unset',
    backgroundColor: 'cardBackgroundColor',
    padding: '15px',
    direction: { base: 'column', sm: 'row' },
    overflow: 'hidden',
    variant: 'outline',
    minW: '280px',
    transition:
      'box-shadow .6s cubic-bezier(.19,1,.22,1), background-color .3s',
    _hover: {
      backgroundColor: 'unset',
      boxShadow:
        '-1px 1px 2px rgba(0, 0, 0, .14), -3px 3px 6px rgba(0, 0, 0, .12), -6px 6px 12px rgba(0, 0, 0, .08), -15px 15px 30px rgba(0, 0, 0, .04)',
    },
  };
  return (
    <Card {...cardProps}>
      <CardBody padding={'0'}>
        <Heading
          fontSize={'1.5rem'}
          marginBottom={'10px'}
          fontWeight={'semibold'}
          color={siteColorCodes[title]}
        >
          {title}
        </Heading>
        <UnorderedList styleType="'☄ '">
          {features.map(renderFeatureItem)}
        </UnorderedList>
      </CardBody>
    </Card>
  );
};

const renderFeatureItem = (feature, index) => (
  <ListItem key={index} whiteSpace="nowrap" fontSize={'1.1rem'}>
    {feature}
  </ListItem>
);

const Features = () => {
  return (
    <Center>
      <VStack>
        <Text
          fontSize="3xl"
          fontWeight={'semibold'}
          marginTop={'45px'}
          marginBottom={'45px'}
        >
          Features
        </Text>
        <Flex wrap="wrap" maxWidth="1200px" justifyContent="flex-start">
          {Object.entries(featureList).map(([siteName, features]) => (
            <FeatureCard key={siteName} title={siteName} features={features} />
          ))}
        </Flex>
      </VStack>
    </Center>
  );
};

export default Features;
