import { Heading, Card, CardBody, Image } from '@chakra-ui/react';
import React from 'react';
import scholarshipFull from '../images/scholarship_full.png';

const Scholarship = () => {
  return (
    <div
      className="footer-element"
      style={{
        marginTop: '50px',
      }}
    >
      <Card>
        <CardBody display={'flex'} justifyContent={'center'} flexDir={'column'} padding={'70px'}>
          <Image src={scholarshipFull} height={'10rem'} objectFit={'contain'} />
          <Heading textAlign={'center'} marginTop={'70px'}>
            You are receiving a scholarship that covers the full cost
          </Heading>
        </CardBody>
      </Card>
    </div>
  );
};

export default Scholarship;
