import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <Flex
      backgroundColor={'#f0e6c8 '}
      justifyContent="space-around"
      alignItems={'center'}
      marginTop="50px"
      paddingTop="20px"
      paddingBottom="20px"
      height={'fit-content'}
      width={'100vw'}
      position="fixed"
      bottom={0}
      left={0}
    >
      <Link to="/aboutUs">
        <Text fontSize={'lg'}>About Us</Text>
      </Link>
      <Link to="/privacy">
        <Text fontSize={'lg'}>Privacy</Text>
      </Link>
      <Link to="/tnc">
        <Text fontSize={'lg'}>Terms & Conditions</Text>
      </Link>
      <Link to="/refundPolicy">
        <Text fontSize={'lg'}>Refund Policy</Text>
      </Link>
      <Link to="/contactUs">
        <Text fontSize={'lg'}>Contact Us</Text>
      </Link>
    </Flex>
  );
};

export default Footer;
