import { Text } from '@chakra-ui/react';
import { initializePaddle } from '@paddle/paddle-js';
import { useState, useEffect } from 'react';

const PaddleCheckout = () => {
  const [paddle, setPaddle] = useState();

  useEffect(() => {
    initializePaddle({
      token: 'live_d68fa3c3039e0e1f81dcfc3ca81',
    }).then(paddleInstance => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const openCheckout = () => {
    paddle?.Checkout.open({
      items: [{ priceId: 'pri_01hzs6d9khpcdqj0m751bsbsez', quantity: 1 }],
    });
  };

  return (
    <Text
      fontSize={'lg'}
      backgroundColor={'#ef9b00'}
      color={'white'}
      width={'100%'}
      textAlign={'center'}
      borderRadius={'1rem'}
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      marginTop={'16px'}
      paddingTop={'5px'}
      paddingBottom={'5px'}
      onClick={openCheckout}
    >
      Subscribe
    </Text>
  );
};

export default PaddleCheckout;
