import React, { useEffect } from 'react';
import { Divider, Box } from '@chakra-ui/react';
import Title from './Title';
import Features from './Features';
import Benefits from './Benefits';
import Pricing from './Pricing';
import ReactGA from 'react-ga4';
const Home = () => {
  useEffect(() => {
    ReactGA.initialize('G-PDLM08SF7W');
    ReactGA.send({
      hitType: 'pageview',
      page: '/',
      title: 'Home Page',
    });
  }, []);

  return (
    <Box as="home" padding={'0 100px 150px 100px'}>
      <Title />
      <Divider marginTop={'70px'} borderColor="gray.300" />
      <Features />
      <Divider marginTop={'45px'} borderColor="gray.300" />
      <Benefits />
      <Divider marginTop={'45px'} borderColor="gray.300" />
      <Pricing />
    </Box>
  );
};

export default Home;
