import { initializePaddle } from '@paddle/paddle-js';
import { useEffect } from 'react';

const PaddleDirectCheckout = () => {
  useEffect(() => {
    initializePaddle({
      token: 'live_d68fa3c3039e0e1f81dcfc3ca81',
    }).then(paddleInstance => {
      paddleInstance?.Checkout.open({
        items: [{ priceId: 'pri_01hzs6d9khpcdqj0m751bsbsez', quantity: 1 }],
      });
    });
  }, []);
};

export default PaddleDirectCheckout;
