import React from 'react';
import './App.css';
import { ChakraProvider, Box } from '@chakra-ui/react';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import Privacy from './components/footer-pages/Privacy';
import Home from './components/Home';
import TnC from './components/footer-pages/TermsAndConditions';
import RefundPolicy from './components/footer-pages/RefundPolicy';
import AboutUs from './components/footer-pages/AboutUs';
import ContactUs from './components/footer-pages/ContactUs';
import PaddleDirectCheckout from './components/PaddleDirect';
import Updated from './components/Updated';
import Scholarship from './components/Scholarship';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box
        position={'relative'}
        className="main"
        margin={'0 200px 0 200px'}
        paddingTop={'50px'}
        height={'100vh'}
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tnc" element={<TnC />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/updated" element={<Updated />} />
          <Route path="/paddleDirect" element={<PaddleDirectCheckout />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
