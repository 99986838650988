import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    cardBackgroundColor: '#fdfbe7',
    accentedTextColor: '#550000',
    backgroundColor: '#fffff0',
    brandColor: '#0080ff',
  },
  styles: {
    global: {
      body: {
        fontFamily: `'Roboto', sans-serif`,
        color: 'gray.700',
        background: '#fffff0',
        fontWeights: {
          light: 300,
          normal: 400,
          medium: 500,
          bold: 700,
        },
      },
    },
  },
});

export default theme;
