import { Text } from '@chakra-ui/react';
import React from 'react';

const AboutUs = () => {
  return (
    <div
      className="footer-element"
      style={{
        marginTop: '50px',
      }}
    >
      <Text fontSize="1.5rem">Hi there! Welcome to UnDistracted!</Text>
      <br />
      <Text fontSize="1.3rem">
        I’m thrilled to share a bit about creating this extension. Back in 2019,
        inspired by Cal Newport’s books "Deep Work" and "Digital Minimalism", I
        embarked on a mission to foster a healthier relationship with social
        media for myself and others.
      </Text>
      <br />
      <Text fontSize="1.3rem">
        What started as a small project has now blossomed into a tool embraced
        by many. I've kept UnDistracted well-maintained and continually updated
        with new features to better serve our growing community.
      </Text>
      <br />
      <Text fontSize="1.3rem">
        As UnDistracted gained popularity, I decided to leave my day job and
        dedicate myself full-time to this passion project. I aim to create more
        software tools that support a healthy, balanced life, both physically
        and mentally.
      </Text>
      <br />
      <Text fontSize="1.3rem">
        Thank you for being part of this journey. Together, we can stay focused
        and live healthier lives!
      </Text>
      <br />
      <Text fontSize="1.3rem">Warm regards,</Text>
      <Text fontSize="1.3rem">Jai, UnDistracted Developer</Text>
      <Text fontSize="1.3rem">Nauka</Text>
    </div>
  );
};

export default AboutUs;
