import {
  Heading,
  Highlight,
  Card,
  CardBody,
  Stack,
  StackDivider,
  Text,
  CardHeader,
  HStack,
  Image,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
const Updated = () => {
  useEffect(() => {
    ReactGA.initialize('G-PDLM08SF7W');
    ReactGA.send({
      hitType: 'pageview',
      page: '/updated',
      title: 'Updated Page',
    });
  }, []);

  return (
    <div
      style={{
        marginTop: '50px',
      }}
    >
      <Heading lineHeight="tall">
        <Highlight
          query="Version 3.0"
          styles={{
            px: '3',
            py: '1',
            rounded: 'full',
            bg: 'orange.100',
            color: 'purple.800',
          }}
        >
          Updated to Version 3.0
        </Highlight>
      </Heading>
      <Card marginTop={'70px'} width={'fit-content'}>
        <CardHeader>
          <Heading size="md">New features</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {cardPanel(
              'Subscribe to unlock paid features',
              'https://res.cloudinary.com/dxmi9d3vj/image/upload/v1718624323/UnDistracted/sub.png'
            )}
            {cardPanel(
              'Pause filters on certain days',
              'https://res.cloudinary.com/dxmi9d3vj/image/upload/v1718624323/UnDistracted/days.png'
            )}
            {cardPanel(
              'Lock filters and settings with password',
              'https://res.cloudinary.com/dxmi9d3vj/image/upload/v1718624323/UnDistracted/lock.png'
            )}
            {cardPanel(
              'Apply for a free subscription as a student',
              'https://res.cloudinary.com/dxmi9d3vj/image/upload/v1718624323/UnDistracted/scholar.png'
            )}
          </Stack>
        </CardBody>
      </Card>
    </div>
  );
};

export default Updated;
function cardPanel(text, image) {
  return (
    <HStack justifyContent={'space-between'}>
      <Text fontSize={'1.5rem'} marginRight={'70px'}>
        {text}
      </Text>
      <Image objectFit="contain" src={image} height={'5rem'} maxW={'30rem'} />
    </HStack>
  );
}
